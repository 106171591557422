import * as React from 'react';
import { StaticNavigation } from '../navigation/navigation';
import { LogoImage } from '../../components/imageMedia/logoImg';
import { LogoImageSearch } from '../../components/imageMedia/logoImgSearch';
import './header.css';
/**
 * A simple header component, displaying the page title and the language switcher.
 */
export const Header: React.FC<{
  currentPath: string;
}> = ({ currentPath }) => {
  let isNotDefault = false;
  let isSearchPath = false;
  let isContactPath = false;

  if (currentPath == '/search/') {
    isSearchPath = true;
  }

  if (currentPath == '/terms/' || currentPath == '/privacypolicy/') {
    isNotDefault = true;
  }

  if (currentPath == '/contact/') {
    isContactPath = true;
  }

  return (
    <div className="page-centered section--header">
      <header className="relative">
        <section className="inner px-8 py-8">
          <h1 className="w-48 relative z-10">
            {!isSearchPath && !isNotDefault ?
              <LogoImage /> : <LogoImageSearch />
            }
            <a className="link-block inset-0 absolute" href="/"></a>
          </h1>
          <StaticNavigation />
        </section>
      </header>
    </div>
  );
};
