import * as React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/tailwind.css';
import { Header } from '../components/header/header';
import { Footer } from '../components/footer/footer';
import { useEffect } from 'react';
import i18n from '../../i18n';
import { CurrentPathProvider } from '../hooks/current_path';
import { delocalizePath } from '../components/languages/languages';
import { defaultLanguage } from '../../languages';
import classnames from 'classnames';
import { DiverBackground } from '../components/bgimg/diverbgimg';
import { AboutBackground } from '../components/bgimg/aboutbgimg';
import { ContactBackground } from '../components/bgimg/contactbgimg';
import { NewsletterButton } from '../components/newsletter/newsletter';

declare global {
  interface Window {
    __gatsby_language: string;
  }
}

/**
 * Global layout component.
 *
 * This layout will be shared across all pages.
 *
 * TODO: Adapt this layout to your needs.
 * TODO: Remove Language if we don't need it?
 * https://www.gatsbyjs.org/packages/gatsby-plugin-layout/
 */
const Layout: React.FC<{
  /**
   * The location object passed in by Gatsby.
   */
  location: Location;
  /**
   * The page context assembled in gatsby-node.js
   */
  pageContext: {
    language: string;
  };
  path: string;
}> = ({ children, location, pageContext, path }) => {
  useEffect(() => {
    i18n.changeLanguage(pageContext.language);
    if (typeof window !== 'undefined') {
      window.__gatsby_language = pageContext.language;
    }
  }, [pageContext.language]);

  const pathClass = path;

  let isNotDefault = false;
  let isAboutPath = false;
  let isSearchPath = false;
  let isContactPath = false;
  let isConfirmationPath = false;

  if (pathClass == '/search/') {
    isSearchPath = true;
  }

  if (pathClass == '/about/') {
    isAboutPath = true;
  }

  if (pathClass == '/terms/' || pathClass == '/privacypolicy/') {
    isNotDefault = true;
  }

  if (pathClass == '/contact/') {
    isContactPath = true;
  }

  if (pathClass == '/confirmation/') {
    isConfirmationPath = true;
  }

  return (
    <CurrentPathProvider
      path={delocalizePath(
        location.pathname,
        pageContext.language,
        defaultLanguage
      )}
    >
      <Helmet title="BlueValue" defer={false} titleTemplate="%s - BlueValue">
        <meta
          name="description"
          content="BlueValue is a searchable database of simplified and useful ecosystem valuation information."
        />
        <body
          className={`${classnames({
            'page-theme--search': pathClass == '/search/',
            'page-theme--default': pathClass !== '/search/',
            'page-theme--page':
              pathClass == '/terms/' || pathClass == '/privacypolicy/',
            'page-theme--contact': pathClass == '/contact/',
            'page-theme--about': pathClass == '/about/',
            'page-theme--confirmation': pathClass == '/confirmation/',
          })}`}
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-Z4HPEH55M6"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-Z4HPEH55M6');`}
        </script>
      </Helmet>

      {!isSearchPath &&
      !isAboutPath &&
      !isNotDefault &&
      !isContactPath &&
      !isConfirmationPath ? (
        <DiverBackground className={'full-bg diver'} />
      ) : null}

      {isAboutPath ? <AboutBackground className={'full-bg about'} /> : null}

      {isContactPath || isConfirmationPath ? (
        <ContactBackground className={'full-bg contact-bgimg'} />
      ) : null}

      <div className="page-wrapper">
        <Header currentPath={pathClass} />
        <div className="page-centered main">{children}</div>
        <NewsletterButton />
        <Footer currentPath={pathClass} />
      </div>
    </CurrentPathProvider>
  );
};

export default Layout;
