// Make sure the global stylesheet is added to the gatsby build.
import '!style-loader!css-loader!postcss-loader!./src/styles/tailwind.css';

// Export the central `wrapRootElement` function.
export { wrapRootElement } from './src/utils/wrap-root-element';

// ES6 way
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
  }
}
