import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedLink } from '../../utils/localized-link';
import classnames from 'classnames';
import { useCurrentPath } from '../../hooks/current_path';
import './navigation.css';

/**
 * Helper method to determine if a path is a sub-path of another one.
 */
export const isSubPathOf = (subPath: string, path: string) => {
  return ((a: string[], b: string[]) =>
    a.map((v, i) => b[i] === v).reduce((p, c) => p && c))(
    subPath.split('/').slice(0, path.split('/').length),
    path.split('/')
  );
};

/**
 * The navigation UI element.
 *
 * Accepts a list of navigation items and the current path as arguments.
 */
export const Navigation: React.FC<{
  /**
   * The list of navigation items.
   */
  items: {
    /**
     * The navigation item label.
     */
    label: string;
    /**
     * The path to navigate to.
     */
    path: string;
    /**
     * A brief description of the target page. Displayed on hover.
     */
    description: string;
  }[];

  /**
   * The current page path.
   */
  currentPath: string;
}> = ({ items, currentPath }) => {
  const { t } = useTranslation();

  // Mobile hide/show searches
  const [toggleMobileMenu, settoggleMobileMenu] = useState(false);
  const [seachMobileClass, setSearchMobileClass] = useState('show-search');

  useEffect(() => {
    if (!toggleMobileMenu) {
      setSearchMobileClass('close');
    }
    else{
      setSearchMobileClass('active');
    }
  }, [toggleMobileMenu]);

  return items.length ? (
    <nav
      role="navigation"
      aria-labelledby="navigation"
      className={`absolute close-button ${seachMobileClass}`}
    >
      <button
        className={`hamburger lg:hidden close-button ${seachMobileClass}`}
        onClick={() => settoggleMobileMenu(!toggleMobileMenu)}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <h2 id="navigation" className="sr-only">
        Main navigation
      </h2>
      <ul className="lg:flex hidden">
        {items.map(item => (
          <li key={item.path}>
            <LocalizedLink
              onClick={() => settoggleMobileMenu(!toggleMobileMenu)}
              to={item.path}
              title={item.description}
              className={`block lg:mx-12 first:ml-0 last:mr-0 px-8 lg:px-4 py-6 w-full ${classnames(
                {
                  'border-bv-white': !isSubPathOf(currentPath, item.path),
                  'border-bv-green': isSubPathOf(currentPath, item.path),
                }
              )}`}
            >
              {t(item.label)}
            </LocalizedLink>
          </li>
        ))}
      </ul>
    </nav>
  ) : null;
};

export const StaticNavigation: React.FC = () => {
  const currentPath = useCurrentPath();

  // Use static query allows to execute a query at build time from any
  // component. But without arguments.
  // TODO: Learn about static queries.
  // https://www.gatsbyjs.org/docs/static-query/#usestaticquery
  const data = useStaticQuery<{
    site: {
      siteMetadata: {
        navigation: {
          path: string;
          description: string;
          label: string;
        }[];
      };
    };
  }>(graphql`
    query StaticNavigationQuery {
      site {
        siteMetadata {
          navigation {
            description
            label
            path
          }
        }
      }
    }
  `);

  return (
    <Navigation
      items={data.site.siteMetadata.navigation}
      currentPath={currentPath}
    />
  );
};
