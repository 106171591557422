import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import './bgimg.css';
import BackgroundImage from 'gatsby-background-image-es5';

export const ContactBackground: React.FC<{
  className: any,
}> = ({ className }) => {
  const {
    contactbg,
    contactbgWide
  } = useStaticQuery(
    graphql`
      query {
        contactbg: file(
          relativePath: { eq: "contactbg768.jpg" }
        ) {
          childImageSharp {
            fluid(
              quality: 90,
              maxWidth: 768,
              srcSetBreakpoints: [ 414, 768 ]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        contactbgWide: file(
          relativePath: { eq: "contactbg.jpg" }
        ) {
          childImageSharp {
            fluid(
              quality: 75,
              maxWidth: 1920,
              srcSetBreakpoints: [768, 1024, 1440, 1920]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundFluidImageStack = [
    contactbg.childImageSharp.fluid,
    {
      ...contactbgWide.childImageSharp.fluid,
      media: '(min-width: 769px)',
    }
  ];

  return (
    <BackgroundImage
      Tag={'div'}
      className={className}
      fluid={backgroundFluidImageStack}
      style={{
        // Defaults are overwrite-able by setting one or each of the following:
        position: 'absolute',
        backgroundPosition: 'center top',
        objectFit: 'cover',
        backgroundSize: 'cover',
        opacity: '1',
      }}
    >
    </BackgroundImage>
  );
};
