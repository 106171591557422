import React from 'react';
import './newsletter.css';
import ReCAPTCHA from 'react-google-recaptcha';

export const NewsletterButton: React.FC<{}> = ({}) => {
  const onSubmitAsync = (e) => {
    e.preventDefault();
    recaptchaRef.current.execute();
  };
  function onChange(value) {
    // when the user solves the reCaptcha
    document.getElementById('newsletterForm').submit();
    recaptchaRef.current.reset();
  }
  const recaptchaRef = React.createRef();
  return (
    <section className="clearfix section--newsletter bg-white">
      <div className="formwrapper">
        <form
          id="newsletterForm"
          className="px-6 p-10 flex-wrap flex justify-center items-stretch flex-wrap"
          action="https://listserv.tamucc.edu/mailman/subscribe/hrinews"
          method="post"
          target="_blank"
          onSubmit={onSubmitAsync}
        >
          <span className="subscribe px-2 text-center lg:text-left py-3 font-semibold w-full md:w-1/2 lg:w-1/4 mb-2">Subscribe to our Newsletter </span>
          <input name="name" type="text"
            placeholder="Name"
            id="name"
            required
            className="ml-2 appearance-none border rounded py-3 px-5 leading-tight focus:outline-none focus:shadow-outline bg-white font-semibold w-full md:w-1/2 lg:w-1/6 mb-2" />
          <input name="email"
            type="text"
            placeholder="Email Address"
            id="email"
            required
            className="ml-2 appearance-none border rounded py-3 px-5 leading-tight focus:outline-none focus:shadow-outline bg-white font-semibold w-full md:w-1/2 lg:w-1/6 mb-2" />
          <input
            name="email-button"
            type="submit"
            value="Subscribe"
            className="btn btn-green ml-2 px-6 py-4 btn-submit w-full md:w-1/2 lg:w-1/6"
          />
          &nbsp;
          <ReCAPTCHA
            size="invisible"
            ref={recaptchaRef}
            sitekey="6Lc496IZAAAAAOfZ5hOMJEEbdMMQQdIVIfypZ7PC"
            onChange={onChange}
          />
        </form>
      </div>
    </section>
  );
};
