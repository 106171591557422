import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { LocalizedLink } from '../../utils/localized-link';
import { useCurrentPath } from '../../hooks/current_path';

/**
 * Helper method to determine if a path is a sub-path of another one.
 */
export const isSubPathOf = (subPath: string, path: string) => {
  return ((a: string[], b: string[]) =>
    a.map((v, i) => b[i] === v).reduce((p, c) => p && c))(
    subPath.split('/').slice(0, path.split('/').length),
    path.split('/')
  );
};

/**
 * The navigation UI element.
 *
 * Accepts a list of navigation items and the current path as arguments.
 */
export const FooterNav: React.FC<{
  /**
   * The list of navigation items.
   */
  items: {
    /**
     * The navigation item label.
     */
    label: string;
    /**
     * The path to navigate to.
     */
    path: string;
    /**
     * A brief description of the target page. Displayed on hover.
     */
    description: string;
  }[];

  /**
   * The current page path.
   */
  currentPath: string;
}> = ({ items }) => {

  return items.length ? (
    <>
      {items.map(item => (

        <LocalizedLink
          to={item.path}
          title={item.description}
          key={item.path}
        >
          {item.label}
        </LocalizedLink>
      ))}
    </>
  ) : null;
};

export const StaticFooterNavigation: React.FC = () => {
  const currentPath = useCurrentPath();

  const data = useStaticQuery<{
    site: {
      siteMetadata: {
        footernav: {
          path: string;
          description: string;
          label: string;
        }[];
      };
    };
  }>(graphql`
    query StaticFooterNavigation {
      site {
        siteMetadata {
          footernav {
            description
            label
            path
          }
        }
      }
    }
  `);

  return (
    <FooterNav
      items={data.site.siteMetadata.footernav}
      currentPath={currentPath}
    />
  );
};
