import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import './imageMedia.css';

export const LogoImageSearch = () => {
  const {
    logoImage,
  } = useStaticQuery(
    graphql`
      query {
        logoImage: file(relativePath: { eq: "logo_blue2x.png" }) {
          childImageSharp {
            fluid(maxWidth: 178, quality: 100) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `);

  return (
    <>
      <Img
        Tag={'span'}
        fluid={logoImage.childImageSharp.fluid}
        className={'logo-search'}
        objectFit="contain"
        alt="Bluevalue"
      />
    </>
  );
};
